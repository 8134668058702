<template>
  <section class="menu-item-list">
    <h2 class="menu-item-list__title h2"><span>{{ languages[this.lang] }}</span></h2>
    <table class="table table-hover">
      <thead>
      <tr>
        <th>Наименование</th>
        <th>Кол-во</th>
        <th>Цена</th>
        <th>Сумма</th>
      </tr>
      </thead>
      <tbody>

      <tr  v-for="(order) in orderList" :key="order.id">
        <th>{{ order["name_" + this.lang] }}</th>
        <td>{{orders.get(order.id)}}</td>
        <td>{{ order.price }}</td>
        <td>  {{  order.price * orders.get(order.id)}} </td>
      </tr>

      </tbody>
    </table>
    <div>
      <h5><span v-if="this.lang=='en'">Total</span>
        <span v-if="this.lang=='ru'">Итог</span>
        <span v-if="this.lang=='uz'">Жами</span>
        :  {{  orderList.map(e=> e.price  * orders.get(e.id)).reduce((a,b)=>a+b,0)}} <span v-if="this.lang=='en'">sum</span>
        <span v-if="this.lang=='ru' || this.lang=='uz'">сум</span>
      </h5>
      <h5><span v-if="this.lang=='en'">Service</span>
        <span v-if="this.lang=='ru'">Услуг</span>
        <span v-if="this.lang=='uz'">Хизмат учун</span>
        :  {{Math.trunc(this.services(this.service))}}
        <span>%</span>
      </h5>
      <h5><span v-if="this.lang=='en'">Total</span>
        <span v-if="this.lang=='ru'">Итог</span>
        <span v-if="this.lang=='uz'">Жами</span>
       :  {{ Number.parseFloat(orderList.map(e=> e.price  * orders.get(e.id)).reduce((a,b)=>a+b,0)*(this.service)).toFixed(2) }} <span v-if="this.lang=='en'">sum</span>
        <span v-if="this.lang=='ru' || this.lang=='uz'">сум</span>
      </h5>
    </div>
    <button class="place-order focus" v-on:click="clear_trash">
      <span v-if="this.lang=='en'">Clear</span>
      <span v-if="this.lang=='ru'">Очистить</span>
      <span v-if="this.lang=='uz'">Ўчириш</span>
    </button>
  </section>
</template>

<script>
import {parse, stringify} from "@/utils/json";
import {httpClient} from "@/utils/http";
import "../assets/bootstrap.min.css"
export default {
  name: "OrderView",
  data: () => ({
    hasOrder: false,
    lang: 'uz',
    service: 1,
    total: 0,
    orders: new Map(),
    quantity:[],
    orderList: [],
    languages:{
      'uz':'Буюртмаларим',
      'ru':'Заказы',
      'en':'Orders'
    }
  }),
  created() {
    this.lang = this.$route.query.lang;
    this.service = this.$route.query.service;
  },
  mounted() {
    let orders = parse(localStorage.getItem('orders'))

    httpClient.post('/orders', Object.keys(orders))
      .then(response => {
        this.orderList = response.data
        for (let i in orders) {
          this.orders.set(parse(i), orders[i])
        }

      }).catch(e => console.log(e));

    this.hasOrder = Object.keys(parse(localStorage.getItem('orders'))).length > 0

  },
  methods: {
    imageLoaded(e) {
      e.target.style = "opacity: 1"
    },
    selectOrder(id, val) {
      if (val > 0) {
        this.orders.set(id, val)
      } else {
        this.orders.delete(id)
        this.orderList = this.orderList.filter(order => order.id !== id)
      }

      localStorage.setItem('orders', stringify(Object.fromEntries(this.orders)))
    },
    services(index){
      return ((index) * 10-10)*10;
    },
    clear_trash(){
      localStorage.removeItem('orders');
     //console.log(this.orders);
      this.$router.back();
    }
  }
}
</script>
