<template>
  <div class="cafe-list">
    <ShowStore :visible="visible"/>
    <div class="cafe-list-body">
      <div class="menu-item-search">
        <div class="menu-item-search-form _open">
          <form action class="menu-item-search-form-field" method="post">
            <input type="search" v-model="search" @keydown.enter.prevent="searchItem" :placeholder="hintSearch" class="base-form-input">
            <button type="button" @click="searchItem" class="round-button focus _expand-clickable ripple menu-item-search-form-button">
              <img src="/img/search.svg" alt="img">
            </button>
          </form>
        </div>
        <div v-if="meals.length > 0" class="menu-item-search-result">
          <search-result :meals="meals"/>
        </div>
      </div>
    </div>
  </div>
  <div class="place-content wrapper">
    <div class="category-list" style="margin-bottom: 50px">
      <div v-for="(category) in categories" :key="category.id" class="category-item-margin">
        <div class="category-list__item">
          <div class="category-item">
            <!-- TODO -->
            <router-link :to="{name:'dishes', params:{id:category.id, category_name:category['name_'+this.lang]}, query:{lang: this.lang, service: this.service}}"  class="category-item__link focus" :style="{ backgroundImage: `url(http://admin.xivayakkasaroy.com${category.img})` }">
              <h2 class="h2" style="font-family: 'Rubik', Arial, sans-serif">
                {{category["name_" + this.lang] }}
              </h2>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {httpClient} from "@/utils/http";
import "../assets/category.css"
import ShowStore from "@/components/ShowStore";
import {parse, stringify} from "@/utils/json";
import SearchResult from "@/components/SearchResult";

export default {
  name: 'HelloWorld',
  components: {SearchResult, ShowStore},
  el: '#app',
  data() {
    return {
      categories: null,
      lang: "uz",
      visible: false,
      search: '',
      meals: [],
      hintSearch: "Qidirish",
      service: 1,
    };
  },
  mounted() {
    this.hintSearch = {'uz': "Qidirish", 'ru': "Поиск", 'en': "Search"}[this.$route.query.lang]
  },
  created() {
    this.lang = this.$route.query.lang;
    this.service = this.$route.query.service;
    this.visible = Object.keys(parse(localStorage.getItem('orders'))).length > 0

    httpClient.get('/categories/' + this.$route.params.id)
      .then(response => (this.categories = response.data))
      .catch(error => console.log(error));
  },
  methods: {
    searchItem() {
      httpClient.post('/search', stringify({
        name: this.search
      }), {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => (this.meals = response.data))
        .catch(error => console.log(error));
    }
  }
}
</script>
