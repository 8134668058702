<template>
  <main class="place" style="--color-primary:#150B5F;--color-primary-1:#150B5F;--color-primary-4:rgba(178,0,14,0.15);--color-primary-5:rgba(178,0,14,0.1);">
    <div itemscope="itemscope" class="place-body">
      <div class="place-header wrapper">
        <div class="place-header__bg" style="background-color: #150B5F"></div>
        <div class="place-header__main">
          <a href="#" @click.prevent="setLocale" aria-current="page" class="place-header__logo focus nuxt-link-exact-active nuxt-link-active _logo">
            <img src="/img/logo.png" alt="Xiva Yakkasaroy restaurant">
          </a>
          <div class="place-header__actions">
            <div class="place-header__actions__locale-switcher">
              <label for="locale-switcher" class="visible-hidden">Change language</label>
              <select id="locale-switcher" v-model="lang" @change="setLocale" name="lang" class="locale-switcher focus">
                <option value="en">
                  English
                </option>
                <option value="ru">
                  Русский
                </option>
                <option value="uz">
                  Uzbekcha
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="place-content wrapper">
        <h1 class="place-title"><span>{{title_name}}</span></h1>
        <div class="place-info">
          <div class="place-info__address">
            <div class="place-info__block">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="16" height="16" fill="currentColor">
                <g>
                  <circle cx="128" cy="104" r="32" fill="none" stroke="currentColor"
                          stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="16">
                  </circle>
                  <path d="M208,104c0,72-80,128-80,128S48,176,48,104a80,80,0,0,1,160,0Z"
                        fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="16">
                  </path>
                </g>
              </svg>
              <span>
                <a href="#" target="_blank">
                  {{location}}
                </a>
              </span>
            </div>
            <div class="place-info__block">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="16" height="16" fill="currentColor">
                <g>
                  <path d="M92.47629,124.81528a84.34782,84.34782,0,0,0,39.05334,38.8759,7.92754,7.92754,0,0,0,7.8287-.59231L164.394,146.40453a8,8,0,0,1,7.58966-.69723l46.837,20.073A7.97345,7.97345,0,0,1,223.619,174.077,48.00882,48.00882,0,0,1,176,216,136,136,0,0,1,40,80,48.00882,48.00882,0,0,1,81.923,32.381a7.97345,7.97345,0,0,1,8.29668,4.79823L110.31019,84.0571a8,8,0,0,1-.65931,7.53226L93.01449,117.00909A7.9287,7.9287,0,0,0,92.47629,124.81528Z"
                    fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="16">
                  </path>
                </g>
              </svg>
              <span itemprop="telephone">
                <a href="tel:+998991370900">+998991370900</a><br>
                <a href="tel:+998992575377">+998992575377</a>
              </span>


            </div>
          </div>
        </div>
        <div class="place-layout">
          <div class="menu-list right-fade">
            <div class="base-scrollbar">
              <div class="menu-list__item" v-for="(category) in categories" :key="category.id">
                <div class="menu router-link-acitve" v-if='category.parent_id===null'>
                  <router-link :to="{name:'home', params:{id: category.id,}, query:{lang: this.lang, service: this.service}}">
                    <a class="menu__button focus"><span>{{ category["name_" + this.lang] }}</span></a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <router-view :key="$route.fullPath"/>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import {httpClient} from "@/utils/http";

export default {
  el: '#app',
  data() {
    return {
      categories: null,
      lang: "uz",
      service: 1,
      location:"O'zbekiston Respublikasi, Xorazm viloyati, Xiva tumani, Pirnaxos mahallasi, ",
      title_name:'Yakka Saroy restorani'
    };
  },
  methods: {
    setLocale() {
      localStorage.setItem('locale', this.lang);
      this.service = this.$route.query.service || 1;
      this.$router.push({
        name: 'home',
        params: {
          id: this.categories[0]?.id
        },
        query: {
          lang: this.lang,
          service: this.service,
        }
      });

      this.changeLang()
    },
    changeLang() {
      this.location = {'uz': "O`zbekiston Respublikasi, Xorazm viloyati, Xiva tumani, Pirnaxos mahallasi, Milliy g`urur ko`chasi, 47-uy", 'ru': "дом 47,улица Миллий Гурур, махалля Пирнахос, Хивинский район, Хорезмская область, Узбекистан", 'en': "47,street Milliy gurur,Pirnakhos neighborhood,Khiva district, Khorezm region, Uzbekistan"}[this.lang];
      this.title_name = {'uz': "Yakka Saroy restorani", 'ru': "Ресторан Якка Cарай", 'en': "Yakka Saray Restaurant"}[this.lang];
      this.service = this.$route.query.service || 1;
    }
  },
  mounted() {
    this.lang = localStorage.getItem('locale') || 'uz';

    httpClient.get('/categories')
      .then(response => {
        this.categories = response.data;


        if (this.$route.params.id) {
          this.changeLang();
        } else {
          this.setLocale();
        }

      }).catch(error => console.log(error));
  }
}
</script>
