<template>
  <div class="dish-order-action" :class="quantity ? '_in-order' : ''">
    <button @click="decrease" class="dish-order-action-button _remove ripple focus button"
            :class="{visible: quantity > 0}">
      <img :src="minus" alt="img">
    </button>
    <span class="dish-order-action-count" :class="{visible: quantity > 0}">{{ quantity }}</span>
    <button :class="'dish-order-action-button _add ripple focus button' + (quantity > 0 ? ' _in-order' : '')" @click="onClick">
      <img :src="quantity>0 ? plus_after : plus" alt="img">
    </button>
  </div>
</template>

<script>
import plus from "../assets/plus.svg"
import minus from "../assets/minus.svg"
import plus_after from "../assets/plus_after.svg"
import {parse} from "@/utils/json";

import "../assets/ripple.css"


export default {
  props: {
    id: {
      default: 0
    },
    type:{
      default: 1
    }
  },
  data() {
    return {
      plus: plus,
      minus: minus,
      quantity: 0,
      plus_after: plus_after,

      }
  },
  mounted() {
    let orders = parse(localStorage.getItem('orders'))
    if (orders[this.id]) {
      this.quantity = orders[this.id];
    }

  },

  methods: {
    onClick(e) {
      this.quantity=this.quantity+parseFloat(this.type);
      this.updateState();
      this.ripple_animation(e.target);
    },
    ripple_animation(input) {
      input.classList.add("pulse")
      setTimeout(function() {
        input.classList.remove("pulse");
      }, 800);
    },
    decrease(e) {
      this.quantity=this.quantity-parseFloat(this.type);
      this.updateState();
      this.ripple_animation(e.target);
    },
    updateState() {
      this.$emit('ccl', this.id, this.quantity)
    }
  }
}
</script>

<style scoped>
.visible {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}
</style>
