<template>
  <ShowStore :visible="orders.size > 0"/>

  <section class="menu-item-list">
    <h2 class="menu-item-list__title h2"><span>{{ category_name }}</span></h2>

    <div v-for="(dishes) in category" :key="dishes.id" class="menu-item-list__item">
      <article v-if="!dishes.status" :class="dishes.img ? 'dish': 'dish _without-image'">
        <div v-if="dishes.img" class="base-lazy dish-image">
          <div class="base-lazy__loader"></div>
          <img @load="imageLoaded" @click="openModal('http://admin.xivayakkasaroy.com' + dishes.img)"
               :src="'http://admin.xivayakkasaroy.com' + dishes.img"
               alt="Yakkasaroy taomlari" class="dish-image__img">
        </div>
        <div class="dish-content">
          <div class="dish-body">
            <div class="dish-header">
              <h3 class="dish-title">
                <span>{{ dishes["name_" + this.lang] }}</span>
              </h3>
            </div>
            <div class="dish-description">
              <p>
                {{ dishes['desc_' + this.lang] }}
              </p>
            </div>
          </div>
          <div class="dish-footer">
            <div class="dish-price">
              <div :class="orders.size > 0 ? 'dish-order-action _hasValue':'dish-order-action'"></div>
              <span class="dish-price__current"><b>{{ dishes.price }}</b> <span v-if="this.lang=='en'">sum</span><span v-if="this.lang=='ru'|| this.lang=='uz'">сум</span></span>
            </div>
            <RippleButton @ccl="selectOrder" :id="dishes.id" :type="dishes.type"/>
          </div>
        </div>
        <div class="dish-action"></div>
      </article>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="modal" @click="closeModal">
      <div class="modal-content" @click.stop> <!-- Prevent click event from bubbling up -->
        <span class="close" @click="closeModal">&times;</span>
        <img :src="currentImage" alt="Expanded Dish" class="modal-image">
      </div>
    </div>

  </section>
</template>

<script>
import RippleButton from "@/components/RippleButton";
import ShowStore from "@/components/ShowStore";
import { parse, stringify } from "@/utils/json";
import { httpClient } from "@/utils/http";

import "@/assets/category.css";

export default {
  name: "MyDishes",
  components: { ShowStore, RippleButton },
  data() {
    return {
      category: null,
      lang: "uz",
      orders: new Map(),
      category_name: '',
      service: 1,
      showModal: false,
      currentImage: ''
    };
  },
  mounted() {
    let data = parse(localStorage.getItem('orders'))

    for (let i in data) {
      this.orders.set(parse(i), data[i])
    }
  },
  created() {
    this.lang = this.$route.query.lang;
    this.service = this.$route.query.service;
    this.category_name = this.$route.params.category_name;
    httpClient.get(`/category/${this.$route.params.id}`)
      .then(response => (this.category = response.data))
      .catch(error => console.log(error));
  },
  methods: {
    imageLoaded(e) {
      e.target.style = "opacity: 1"
    },
    closeModal() {
      this.showModal = false;
    },
    selectOrder(id, val) {
      if (val > 0) {
        this.orders.set(id, val)
      } else {
        this.orders.delete(id)
      }

      localStorage.setItem('orders', stringify(Object.fromEntries(this.orders)))
    },
    openModal(imageSrc) {
      this.currentImage = imageSrc;
      this.showModal = true;
    }
  }
}
</script>

<style scoped>
/* Modal Styles */
.modal {
  display: block; /* Show the modal */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.8); /* Black background with a bit of transparency */
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Responsive width */
  max-width: 800px; /* Maximum width */
}

.close {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.modal-image {
  width: 100%;
  height: auto;
  display: block; /* Remove any inline styling */
}

@media (max-width: 768px) {
  .modal-content {
    width: 95%; /* Slightly more width on smaller screens */
  }
}

@media (max-width: 480px) {
  .close {
    font-size: 30px;
    right: 15px;
  }
}
</style>