<template>
  <router-link v-if="visible" :to="{path:'/orders' , query:{lang: this.lang, service: this.service}}" class="place-order focus" style="margin-top:50px">
    <span>{{ languages[this.lang] }}</span>
  </router-link>
</template>
<script>

export default {
  props: {
    visible: {
      default: false
    }
  },
  data: () => ({
    lang: 'uz',
    service: 1,
    languages: {
      'en': "Show my order",
      'uz': "Буюртмаларни кўриш",
      'ru': "Посмотреть заказ"
    }
  }),
  mounted() {
    this.lang = this.$route.query.lang;
    this.service = this.$route.query.service;
  }
}
</script>
