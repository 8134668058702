function parse(data) {
    try {
        if (data === null)
            return {};

        return JSON.parse(data);
    } catch (e) {
        return {};
    }
}

function stringify(data) {
    try {
        return JSON.stringify(data)
    } catch (e) {
        return null
    }
}

export {
    parse, stringify
}
