<template>
  <section class="menu-item-list" style="margin-bottom: 0">
    <h2 class="menu-item-list__title h2"><span>{{ category_name }}</span></h2>
    <div v-for="(dishes) in meals" :key="dishes.id" class="menu-item-list__item">
      <article :class="dishes.img ? 'dish': 'dish _without-image'">
        <div v-if="dishes.img" class="base-lazy dish-image">
          <div class="base-lazy__loader"></div>
          <img @load="imageLoaded" :src="'http://admin.xivayakkasaroy.com' + dishes.img"
               alt="Gavhar taomlari" class="dish-image__img">
        </div>
        <div class="dish-content">
          <div class="dish-body">
            <div class="dish-header">
              <h3 class="dish-title">
                <span>{{ dishes["name_" + this.lang] }}</span>
              </h3>
            </div>
            <div class="dish-description">
              <p>
                {{ dishes['desc_' + this.lang] }}
              </p>
            </div>
          </div>
          <div class="dish-footer">
            <div class="dish-price">
              <div :class="orders.size > 0 ? 'dish-order-action _hasValue':'dish-order-action'"></div>
              <span class="dish-price__current"><b>{{ dishes.price }}</b> <span v-if="this.lang=='en'">sum</span><span v-if="this.lang=='ru'|| this.lang=='uz'">сум</span></span>
            </div>
            <RippleButton @ccl="selectOrder" :id="dishes.id"/>
          </div>
        </div>
        <div class="dish-action"></div>
      </article>
    </div>
  </section>
</template>

<script>
import RippleButton from "@/components/RippleButton";
import {parse, stringify} from "@/utils/json";

import "@/assets/category.css";

export default {
  name: "search-result",
  components: {RippleButton},
  props: {
    meals: {
      default: []
    }
  },
  data() {
    return {
      lang: "uz",
      orders: new Map(),
      category_name: '',
    };
  },
  mounted() {
    let data = parse(localStorage.getItem('orders'))

    for (let i in data) {
      this.orders.set(parse(i), data[i])
    }
  },
  methods: {
    imageLoaded(e) {
      e.target.style = "opacity: 1"
    },
    selectOrder(id, val) {
      if (val > 0) {
        this.orders.set(id, val)
      } else {
        this.orders.delete(id)
      }

      localStorage.setItem('orders', stringify(Object.fromEntries(this.orders)))
    }
  }
}
</script>
