import {createRouter, createWebHistory} from 'vue-router'
import Categories from '../components/Categories.vue'
import Dishes from "@/components/Dishes";
import Order from "@/components/Order";

const routes = [
    {
        path: '/:id?',
        name: 'home',
        component: Categories,
    },
    {
        path: '/dishes/:id/:category_name',
        name: 'dishes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Dishes,
    },
    {
      path: '/orders',
        name: 'OrderView',
        component: Order
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: '_selected',
    linkExactActiveClass: 'exact-active-link',
})

export default router
